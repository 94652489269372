import React, { useEffect, useState } from "react";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import Constant from "../Utils/Constant";
const PartnerMarquee = () => {
  const [partner, setPartner] = useState([]);

  const getPartnerDetails = async () => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_PARTNER);
      if (res?.data?.r) {
        setPartner(res?.data?.r);
      }
    } catch (error) {
      console.error("Error fetching partner details", error);
    }
  };

  useEffect(() => {
    getPartnerDetails();
  }, []);

  return (
    <div className="Partner-Marquee">
      <div className="Partner-Marquee-content">
        <div className="Partner-Marquee-track">
          {partner.map((item, index) => (
            <div className="Partner-Marquee-tag" key={index}>
              <img
                src={`${Constant?.BASE_URL}${item.banner}`}
                alt="Partner Logo"
              />
            </div>
          ))}
         
        </div>
      </div>
    </div>
  );
};

export default PartnerMarquee;
