import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LEFT_LOOPER from "../Assets/Images/leftLooperblog.png";
import RIGHT_LOOPER from "../Assets/Images/rightLooperblog.png";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import moment from "moment/moment";
import Loader from "../Component/Loader";
import Constant from "../Utils/Constant";
const Blogs = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const getBlogs = async () => {
    setLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_ALL_BLOGS);
      if (response?.data?.s === 1) {
        setBlogs(response?.data?.r);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      <div
        id="blog"
        style={{
          width: "100%",
          display: "flex",
          background:
            "linear-gradient(180deg, #EFE9FF 0%, rgba(239, 233, 255, 0.1) 100%)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <img
            src={LEFT_LOOPER}
            className="looper"
            alt=""
            style={{ position: "absolute", top: "-400px", left: "0px" }}
          />
          <img
            src={RIGHT_LOOPER}
            className="looper"
            alt=""
            style={{ position: "absolute", top: "-400px", right: "0px" }}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pt: { xs: 10, md: 22 },
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "30px", md: "38px" },
                fontWeight: "500",
                color: "#0D0C14",
              }}
            >
              Our Latest <span style={{ color: "#B190FF" }}>Blogs</span>
            </Typography>
            <Typography
              sx={{
                color:
                  "linear-gradient(168.95deg, rgba(13, 12, 20, 0.6) 0.74%, rgba(25, 24, 37, 0.6) 98.27%)",
                fontWeight: "400",
                textAlign: "center",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Explore Expert Running Tips & Insights
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              pt: { xs: 8, md: 8 },
              pb: 3,
              minHeight: "750px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: { xs: "30px", md: "80px" },
                flexWrap: "wrap",
                py: 2,
                height: "100%",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    pt: { xs: 8, md: 15 },
                    pb: 3,
                    minHeight: "750px",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                blogs &&
                blogs.slice(0, 3).map((item, index) => {
                  return (
                    <Card
                      key={index}
                      sx={{
                        width: "340px",
                        borderRadius: "12px",
                        height: "500px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="194"
                        image={`${Constant?.BASE_URL}/${item.cover_img}`}
                        alt="Paella dish"
                      />
                      <CardContent sx={{ background: "white" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <CalendarMonthIcon sx={{ color: "#7660AA" }} />
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              {moment(item.created_at).format("DD-MM-YYYY")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "black",
                                fontSize: "20px",
                                fontWeight: "500",
                              }}
                            >
                              {item.title}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              height: "130px",
                              width: "100%",
                              overflow: "scroll",
                            }}
                          >
                            <Typography variant="body2" color="#606060">
                              {item.subtitle}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                      <CardActions disableSpacing sx={{ py: 0, pb: 2, px: 2 }}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            style={{ color: "#B190FF", textDecoration: "none" }}
                          >
                            Learn more
                          </Link>
                          <IconButton
                            sx={{ background: "#D8C7FF" }}
                            onClick={() =>
                              navigate("/blogdetails", {
                                state: { id: item.id },
                              })
                            }
                          >
                            <KeyboardDoubleArrowRightIcon
                              sx={{ color: "black" }}
                            />
                          </IconButton>
                        </Box>
                      </CardActions>
                    </Card>
                  );
                })
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pt: 3,
              }}
            >
              <Button
                sx={{
                  background: "black",
                  width: "150px",
                  textTransform: "none",
                  p: 1,
                  color: "white",
                  "&:hover": { background: "black", color: "white" },
                }}
                onClick={() => navigate("/allblogs")}
              >
                View All
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Blogs;
